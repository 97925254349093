import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './paginas/inicio/inicio.component';
import { RegistroComponent } from './paginas/registro/registro.component';
import { PreguntaComponent } from './paginas/pregunta/pregunta.component';
import { AgradecimientoComponent } from './paginas/agradecimiento/agradecimiento.component';
import { EsperaComponent } from './paginas/espera/espera.component';
import { AuthGuard } from './auth/auth.guard';
import { PublicoGuard } from './auth/publico.guard';

const routes: Routes = [ 
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio',   component: InicioComponent },
  { path: 'agradecimiento',   component: AgradecimientoComponent },
  { path: 'espera',  
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    component: EsperaComponent },
  { path: 'pregunta',  
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ],
    component: PreguntaComponent },
  { path: 'registro',   
   component: RegistroComponent },
  { path: '**', redirectTo: 'inicio', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
